import React, { useMemo } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import User from 'components/data/User';
import Button from 'components/ui-components-v2/Button';
import SplitButton from 'components/ui-components-v2/SplitButton';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Typography from 'components/ui-components-v2/Typography';
import StatusIcon from '../../status-icon';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';

import '../styles/finished-status.scss';

interface Props {
    currentBricks: Brick[];
    setPublishDialogOpen: (open: boolean) => void;
    publishJobs: BrickPublishJobData[];
}

const FinishedPublishStatus = ({ currentBricks, setPublishDialogOpen, publishJobs }: Props) => {
    const options = useMemo(() => {
        const value = [
            {
                key: 'republish',
                title: Translation.get('output.republish', 'bricks'),
                onClick: () => {
                    if (currentBricks.length === 0) return;

                    setPublishDialogOpen(true);
                }
            }
        ];

        // Check if there is anything to download
        const hasDownloadItems = publishJobs.some((publishJob) => {
            if (!publishJob.products) return false;
            return Object.values(publishJob.products).some((product) => {
                if (OutputTabHelpers.getProductType(product).action === 'download') return true;
            });
        });

        // If there are items for donload make download button primary one
        if (hasDownloadItems)
            value.unshift({
                key: 'downloadall',
                title: Translation.get('output.download.outputAll', 'bricks'),
                onClick: () => {
                    OutputTabHelpers.handleDownloadAllResults(publishJobs);
                }
            });

        if (User.hasType('superadmin'))
            value.push({
                key: 'logs',
                title: Translation.get('output.logs', 'bricks'),
                onClick: () => {
                    console.log('logs');
                }
            });

        return value;
    }, [publishJobs]);
    const mainButton = options.shift();
    return (
        <div className="bricks-publish-tab__status__finished">
            <div className="bricks-publish-tab__status__finished__container">
                <StatusIcon status={'success'} />
                <div className="bricks-publish-tab__status__finished__container__title-container">
                    <Typography variant="h5" color="textPrimary">
                        {Translation.get('publishTab.finished-status.title', 'bricks')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {Translation.get('publishTab.finished-status.description', 'bricks')}
                    </Typography>
                </div>
            </div>
            <div className="bricks-publish-tab__status__finished__action">
                {options.length > 1 && mainButton ? (
                    <SplitButton
                        buttonGroupProps={{ color: 'success' }}
                        mainButton={<Button onClick={mainButton.onClick}>{mainButton.title}</Button>}
                        menuItems={options.map((menuItem) => {
                            return (
                                <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
                                    {menuItem.title}
                                </MenuItem>
                            );
                        })}
                    />
                ) : (
                    <Button onClick={mainButton?.onClick} variant="contained" size="small" color="success">
                        {mainButton?.title}
                    </Button>
                )}
            </div>
        </div>
    );
};

export { FinishedPublishStatus };
