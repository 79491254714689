import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import { JobStatus } from 'components/bricks/types/publish.type';

// Helper class for managing the status of the Bricks Publish Tab
class BricksPublishTabStatusHelpers {
    // Calculate the average progress of all running or waiting publish jobs.
    // If there are no such jobs, return 0. This is useful for providing a
    // progress indicator in the UI.
    static calculateProgress(publishJobs: BrickPublishJob[]): number {
        // We're only interested in jobs that are currently in progress
        const runningPublishJobs = publishJobs.filter((job) => job.status === JobStatus.WORKING || job.status === JobStatus.WAITING);

        const totalTasks = runningPublishJobs.reduce((acc, job) => acc + job.totalTasks, 0);
        const finishedTasks = runningPublishJobs.reduce((acc, job) => acc + job.finishedTasks, 0);
        const failedTasks = runningPublishJobs.reduce((acc, job) => acc + job.failedTasks, 0);

        // Calculate the progress
        const progress = totalTasks > 0 ? (finishedTasks + failedTasks) / totalTasks : 0;
        return progress * 100;
    }
}

export { BricksPublishTabStatusHelpers };
