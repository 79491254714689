import React from 'react';
import { TabState } from '../../types/PublishTab.type';
import { PublishSummaryTabData } from './types/tabs.types';
import { PublishSummaryTab } from './components/publish-summary-tab';
import './styles/index.scss';

interface Props {
    tabs: PublishSummaryTabData[];
    setActiveTab: (index: number) => void;
    activeTab: number;
    tabStates: TabState[];
}

const PublishSummaryTabs = ({ tabs, setActiveTab, activeTab, tabStates }: Props) => {
    return (
        <div className="publish-summary-tabs">
            {tabs.length > 1 &&
                tabs.map((tab: PublishSummaryTabData, index: number) => (
                    <div key={tab.title} onClick={() => setActiveTab(index)} className="publish-summary-tabs__tab">
                        <PublishSummaryTab
                            brickIds={tab.ids}
                            subType={tab.subType}
                            subTitle={tab.title}
                            title={`${tab.ids.length}`}
                            active={activeTab === index}
                            tabState={tabStates[index]}
                        />
                    </div>
                ))}
        </div>
    );
};

export { PublishSummaryTabs };
