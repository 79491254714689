import React from 'react';
import MenuItem from 'components/ui-components-v2/MenuItem';
import SplitButton from 'components/ui-components-v2/SplitButton';
import Typography from 'components/ui-components-v2/Typography';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import StatusIcon from '../../status-icon';
import '../styles/error-status.scss';

interface Props {
    setPublishDialogOpen: (open: boolean) => void;
}

const ErrorPublishStatus = ({ setPublishDialogOpen }: Props) => {
    const options = [
        {
            key: 'retry',
            title: Translation.get('output.retry', 'bricks'),
            onClick: () => {
                setPublishDialogOpen(true);
            }
        },
        {
            key: 'logs',
            title: Translation.get('output.logs', 'bricks'),
            onClick: () => {
                console.log('logs');
            }
        }
    ];
    const mainButton = options.shift();

    return (
        <div className="bricks-publish-tab__status__error">
            <div className="bricks-publish-tab__status__error__container">
                <StatusIcon status={'error'} />
                <div className="bricks-publish-tab__status__error__container__title-container">
                    <Typography variant="h5" color="textPrimary">
                        {Translation.get('publishTab.error-status.title', 'bricks')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {Translation.get('publishTab.error-status.description', 'bricks')}
                    </Typography>
                </div>
            </div>
            <div className="bricks-publish-tab__status__error__action">
                {mainButton && (
                    <SplitButton
                        mainButton={
                            <Button key={mainButton.key} onClick={mainButton.onClick}>
                                {mainButton.title}
                            </Button>
                        }
                        menuItems={options.map((menuItem) => {
                            return (
                                <MenuItem
                                    key={menuItem.key}
                                    onClick={() => {
                                        console.log(menuItem.key);
                                    }}>
                                    {menuItem.title}
                                </MenuItem>
                            );
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export { ErrorPublishStatus };
