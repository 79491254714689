import React from 'react';
import classNames from 'classnames';
import Badge from 'components/ui-components-v2/Badge';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/publish-summary-tab-status.scss';
import { BrickPublishStatus } from 'components/bricks/types/publish.type';

interface Props {
    status: BrickPublishStatus;
    active: boolean;
    hasValidationErrors?: boolean;
    count?: number;
}

interface StatusIconProps {
    name: string;
    active?: boolean;
}

const StatusIcon = ({ name, active }: StatusIconProps) => (
    <Icon
        className={classNames('publish-summary-tab-status__icon', {
            'publish-summary-tab-status__icon--active': active
        })}>
        {name}
    </Icon>
);

const statusMap = (active) => ({
    success: <StatusIcon name="check_circle" active={active} />,
    publishing: <CircularProgress size={20} />,
    error: <StatusIcon name="error_outlined" active={active} />,
    info: <StatusIcon name="info" active={active} />,
    blocking: <StatusIcon name="block" active={active} />
});

/**
 * The PublishSummaryTabStatus component is a component that displays the status of the tab based on the status of the publish jobs of the bricks in the tab.
 */
const PublishSummaryTabStatus = ({ status, hasValidationErrors, count, active }: Props) => {
    const icon = hasValidationErrors ? <Icon>block</Icon> : statusMap(active)[status] || <></>;

    return (
        <Badge
            badgeContent={count}
            className={classNames('publish-summary-tab-status', {
                'publish-summary-tab-status--error': status === 'error',
                'publish-summary-tab-status--info': status === 'info',
                'publish-summary-tab-status--success': status === 'success',
                'publish-summary-tab-status--blocking': status === 'blocking'
            })}>
            {icon}
        </Badge>
    );
};

export { PublishSummaryTabStatus };
