import React from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import { useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import { PublishSection } from '../publish-section';
import { PublishSectionDetails } from '../publish-details';
import { PublishSubSection } from '../publish-subsection';
import { PublishSummaryBar } from './components/publish-summary-bar';
import { PublishInfoHelper } from './helpers/publish-info.helper';
import { OutputTabHelpers } from '../../helpers/output-tab.helper';
import './styles/main.scss';

interface Props {
    currentBricks: Brick[];
    selectedPublishJob?: string;
}

const BrickPublishInfo = ({ currentBricks, selectedPublishJob }: Props) => {
    const brickIds = currentBricks.map((brick) => brick.id);
    const { publishJobs } = useBricksPublish(brickIds, selectedPublishJob);

    const multipleBricks = currentBricks.length > 1;

    const validationErrors = OutputTabHelpers.getValidationErrors(currentBricks);

    if (!publishJobs?.length) {
        if (validationErrors.length === 1 || validationErrors.length === 0) return null;
        //classes={{ root: 'publish-info__validation-results' }}
        return (
            <PublishSection title={`Summary`}>
                <PublishSubSection title={`blockers (${validationErrors.length})`}>
                    <PublishSectionDetails items={validationErrors} hasMultipleBricks={multipleBricks} />
                </PublishSubSection>
            </PublishSection>
        );
    }

    const publishResults = PublishInfoHelper.convertPublishJobsToPublishResults(publishJobs);
    const reports = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'reports');
    const errors = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'errors');
    const warnings = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'warnings');

    return (
        <>
            {publishResults.length > 0 && (
                <PublishSection title={`Publish results`}>
                    <PublishSectionDetails items={publishResults} classes={{ root: 'publish-info__results-section' }} hasMultipleBricks={multipleBricks} />
                </PublishSection>
            )}
            {(reports.length > 0 || errors.length > 0 || warnings.length > 0) && (
                <PublishSection title={`Summary`}>
                    <PublishSummaryBar reports={reports.length} errors={errors.length} warnings={warnings.length} />
                    {reports.length > 0 && (
                        <PublishSubSection title={`Reports`} severity="success">
                            <PublishSectionDetails items={reports} severity="success" hasMultipleBricks={multipleBricks} />
                        </PublishSubSection>
                    )}
                    {errors.length > 0 && (
                        <PublishSubSection title={`Errors`} severity="error">
                            <PublishSectionDetails items={errors} severity="error" hasMultipleBricks={multipleBricks} />
                        </PublishSubSection>
                    )}
                    {warnings.length > 0 && (
                        <PublishSubSection title={`Warnings`} severity="warning">
                            <PublishSectionDetails items={warnings} severity="warning" hasMultipleBricks={multipleBricks} />
                        </PublishSubSection>
                    )}
                </PublishSection>
            )}
        </>
    );
};

export { BrickPublishInfo };
