import React from 'react';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { Brick } from 'components/bricks/types/brick.type';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Typography from 'components/ui-components-v2/Typography';
import StatusIcon from '../../status-icon';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';
import '../styles/block-status.scss';

interface Props {
    currentBricks: Brick[];
}

const BlockPublishStatus = ({ currentBricks }: Props) => {
    return (
        <div className="bricks-publish-tab__status__block">
            <div className="bricks-publish-tab__status__block__container">
                <StatusIcon status={'readyToPublish'} />
                <div className="bricks-publish-tab__status__block__container__title-container">
                    <Typography variant="h5" color="textPrimary">
                        {OutputTabHelpers.generatePublishTitle(currentBricks)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {Translation.get('publishTab.block-status.description', 'bricks')}
                    </Typography>
                </div>
            </div>
            <div className="bricks-publish-tab__status__block__action">
                <Tooltip title={Translation.get('output.resolveBlockersToExport', 'bricks')}>
                    <span>
                        <Button variant="contained" disabled>
                            {Translation.get('output.publish.title', 'bricks')}
                        </Button>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

export { BlockPublishStatus };
