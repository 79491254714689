import { JobStatus } from 'components/bricks/types/publish.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { Brick, brickNameMapping, BrickOutputAction } from 'components/bricks/types/brick.type';
import { openSlidePanel } from 'components/bricks/helpers/slide-panel.helpers';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import DownloadService from 'components/bricks/services/download.service';
import { ValidationResults } from 'components/bricks/types/validator.type';
import Translation from 'components/data/Translation';
import LWFiles from 'components/data/Files';
import { PublishResult, PublishTabAction, PublishValidationError } from '../types/PublishTab.type';

class OutputTabHelpers {
    static getPublishStatusByJobstatus(jobStatus: JobStatus): string {
        if (jobStatus === JobStatus.WAITING) return 'publishing';
        if (jobStatus === JobStatus.WORKING) return 'publishing';
        if (jobStatus === JobStatus.FAILED) return 'error';
        if (jobStatus === JobStatus.FINISHED) return 'success';

        return 'readyToPublish';
    }

    static getValidationErrors(currentBricks: Brick[]): PublishValidationError[] {
        const allValidationsResults: ValidationResults = ComponentStoreHelpers.get('Bricks').validationErrors || {};
        const foundValidationErrors: PublishValidationError[] = [];

        for (const brick of currentBricks) {
            if (!brick) return [];
            if (!allValidationsResults[brick.id]) continue;

            for (const validationResult of allValidationsResults[brick.id]) {
                if (validationResult.severity === 'error')
                    foundValidationErrors.push({
                        title: validationResult.message,
                        icon: 'block',
                        brickId: brick.id,
                        action: 'openBrick',
                        buttonName: 'View',
                        value: ''
                    });
            }
        }

        // If there are no validation erors
        if (!foundValidationErrors.length) {
            return [];
        }

        return foundValidationErrors;
    }

    /**
     * Gets product type
     * @param product
     * @returns product type
     */
    static getProductType(product: string): { action: PublishTabAction; icon: string; buttonName?: string } {
        // Regex for download link
        const downloadableExtensions = ['zip', 'pdf', 'jpg', 'jpeg', 'png', 'mp3', 'webm', 'webp', 'mp4', 'gif']; // Add more extensions as needed
        const downloadLinkRegex = new RegExp(`\\.(${downloadableExtensions.join('|')})$`, 'i');

        // Regex to check if it is valid url
        const urlRegex = /^(https?):\/\/[^\s\/$.?#].[^\s]*$/;

        // Check if it is download link
        if (downloadLinkRegex.test(product)) {
            const fileType = product.split('.').pop();

            const icon = (() => {
                switch (fileType) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'gif':
                        return 'image';
                    case 'mp4':
                    case 'webm':
                        return 'videocam';
                    case 'mp3':
                        return 'music_note';
                    case 'pdf':
                        return 'picture_as_pdf';
                    case 'zip':
                        return 'folder_zip';
                    default:
                        return 'bolt';
                }
            })();

            return { action: 'download', icon, buttonName: 'Download' };
        }
        // Check if it is a url
        else if (urlRegex.test(product)) {
            return { action: 'url', icon: 'link', buttonName: 'Open' };
        }

        return { action: 'other', icon: 'notifications' };
    }

    static handlePublishResultButton(item: PublishResult, brick: Brick): void {
        if (item.action === 'url' || item.action === 'download') window.open(item.value);
        else if (item.action === 'openBrick' && brick) openSlidePanel(brick);
    }

    static handleDownloadAllResults(publishJobs: BrickPublishJob[]): void {
        const urls: string[] = [];

        for (const publishJob of publishJobs) {
            if (!publishJob.products?.archive) continue;

            urls.push(publishJob.products?.archive);
        }

        DownloadService.downloadAssets(urls);
    }

    static generatePublishTitle(bricks: Brick[]): string {
        if (bricks.length === 1) {
            return Translation.get('output.outputBrick', 'bricks', { type: brickNameMapping[bricks[0].subType] });
        }

        return Translation.get('output.outputBricks', 'bricks', { type: brickNameMapping[bricks[0].subType], count: bricks.length });
    }
}

export { OutputTabHelpers };
