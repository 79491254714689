import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import { PublishResult } from '../../../types/PublishTab.type';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';

class PublishInfoHelper {
    /**
     * Converts publish jobs to publish results for the publish tab
     * @param publishJobs
     * @param type 'errors', 'warnings' or 'reports'
     * @returnspublish publish results
     */
    static convertPublishJobsAsPublishResults(publishJobs: BrickPublishJobData[], type: 'errors' | 'warnings' | 'reports'): PublishResult[] {
        const results: PublishResult[] = [];

        for (const job of publishJobs) {
            // Check if the type exists in the job and if it is parsed by the publish enigne
            if (!job[type] || typeof job[type] === 'string') continue;

            const typeKeys = Object.keys(job[type] || {});

            for (const key of typeKeys) {
                const item = job[type]?.[key];

                if (!item) continue;

                results.push({
                    title: `${item?.description}`,
                    brickId: job.brickId,
                    icon: 'block'
                } as PublishResult);
            }
        }

        results.flat();

        return results;
    }

    static convertPublishJobsToPublishResults(publishJobs: BrickPublishJobData[]): PublishResult[] {
        return (
            publishJobs
                ?.map((job) => {
                    if (!job.products) return [];

                    return Object.keys(job.products)
                        .map((key) => {
                            const resultOfBrick = job.products?.[key];

                            // filter out the results that are not parsed by the pev3
                            const regex = /^\{\{.*\}\}$/;
                            if (regex.test(resultOfBrick)) return null;

                            const productInfo = OutputTabHelpers.getProductType(resultOfBrick);

                            const publishResult = {
                                title: `${key}`,
                                brickId: job.brickId,
                                action: productInfo.action,
                                value: resultOfBrick,
                                icon: productInfo.icon
                            };

                            switch (productInfo.action) {
                                case 'other':
                                    return publishResult;
                                case 'download':
                                case 'url':
                                    return {
                                        ...publishResult,
                                        buttonName: productInfo.buttonName
                                    };
                            }
                        })
                        .filter((item) => item !== null) as PublishResult[];
                })
                .flat() || []
        );
    }
}

export { PublishInfoHelper };
