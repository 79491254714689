import React from 'react';
import MenuItem from 'components/ui-components-v2/MenuItem';
import SplitButton from 'components/ui-components-v2/SplitButton';
import Button from 'components/ui-components-v2/Button';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import StatusIcon from '../../status-icon';
import '../styles/partial-error-status.scss';

interface Props {
    currentBricks: Brick[];
    setPublishDialogOpen: (open: boolean) => void;
}

const PartialErrorPublishStatus = ({ currentBricks, setPublishDialogOpen }: Props) => {
    const options = [
        {
            key: 'downloadall',
            title: Translation.get('output.download.outputAll', 'bricks'),
            onClick: () => {
                console.log('download');
            }
        },
        {
            key: 'republish',
            title: Translation.get('output.republish', 'bricks'),
            onClick: () => {
                if (currentBricks.length === 0) return;

                setPublishDialogOpen(true);
            }
        },
        {
            key: 'logs',
            title: Translation.get('output.logs', 'bricks'),
            onClick: () => {
                console.log('logs');
            }
        }
    ];
    const mainButton = options.shift();
    return (
        <div className="bricks-publish-tab__status__partial-error">
            <div className="bricks-publish-tab__status__partial-error__container">
                <StatusIcon status={'partialerror'} />
                <div className="bricks-publish-tab__status__partial-error__container__title-container">
                    <span className="bricks-publish-tab__status__partial-error__container__title-container__title">
                        {Translation.get('publishTab.partial-error-status.title', 'bricks')}
                    </span>
                    <span className="bricks-publish-tab__status__partial-error__container__title-container__description">
                        {Translation.get('publishTab.partial-error-status.description', 'bricks')}
                    </span>
                </div>
            </div>
            <div className="bricks-publish-tab__status__partial-error__action">
                {mainButton && (
                    <SplitButton
                        mainButton={
                            <Button key={mainButton.key} onClick={mainButton.onClick}>
                                {mainButton.title}
                            </Button>
                        }
                        menuItems={options.map((menuItem) => {
                            return (
                                <MenuItem
                                    key={menuItem.key}
                                    onClick={() => {
                                        console.log(menuItem.key);
                                    }}>
                                    {menuItem.title}
                                </MenuItem>
                            );
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export { PartialErrorPublishStatus };
