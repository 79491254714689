import React from 'react';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import MenuItem from 'components/ui-components-v2/MenuItem';
import SplitButton from 'components/ui-components-v2/SplitButton';
import Button from 'components/ui-components-v2/Button';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import PublishService from 'components/bricks/services/publish.service';
import StatusIcon from '../../status-icon';
import '../styles/publishing-status.scss';
import { BricksPublishTabStatusHelpers } from '../helpers/status.helper';

interface Props {
    publishJobs: BrickPublishJob[];
}

const PublishingPublishStatus = ({ publishJobs }: Props) => {
    const progress = BricksPublishTabStatusHelpers.calculateProgress(publishJobs);

    const options = [
        {
            key: 'cancel',
            title: Translation.get('output.cancel.copy', 'bricks'),
            onClick: () => {
                handlePublishJobCancel();
            }
        },
        {
            key: 'logs',
            title: Translation.get('output.logs', 'bricks'),
            onClick: () => {
                console.log('logs');
            }
        }
    ];

    const handlePublishJobCancel = () => {
        const jobId = publishJobs[0].jobToken;
        const publishId = publishJobs[0].publishId;

        PublishService.cancelPublishJob(jobId, publishId);
    };

    const mainButton = options.shift();
    return (
        <div className="bricks-publish-tab__status__publishing">
            <div className="bricks-publish-tab__status__publishing__container">
                <div className="bricks-publish-tab__status__publishing__container__info">
                    <StatusIcon status={'publishing'} />
                    <div className="bricks-publish-tab__status__publishing__container__info__title-container">
                        <Typography variant="h5" color="textPrimary">
                            {Translation.get('publishTab.publishing-status.title', 'bricks')}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {Translation.get('publishTab.publishing-status.description', 'bricks')}
                        </Typography>
                    </div>
                </div>
                <div className="bricks-publish-tab__status__publishing__container__action">
                    {mainButton && (
                        <SplitButton
                            buttonGroupProps={{ variant: 'outlined' }}
                            mainButton={
                                <Button key={mainButton.key} onClick={mainButton.onClick}>
                                    {mainButton.title}
                                </Button>
                            }
                            menuItems={options.map((menuItem) => {
                                return (
                                    <MenuItem
                                        key={menuItem.key}
                                        onClick={() => {
                                            console.log(menuItem.key);
                                        }}>
                                        {menuItem.title}
                                    </MenuItem>
                                );
                            })}
                        />
                    )}
                </div>
            </div>
            <LinearProgress className="bricks-publish-tab__status__publishing__progress" variant="determinate" value={progress} />
        </div>
    );
};

export { PublishingPublishStatus };
