import React from 'react';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { Brick } from 'components/bricks/types/brick.type';
import StatusIcon from '../../status-icon';
import '../styles/start-status.scss';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';

interface Props {
    currentBricks: Brick[];
}

const StartPublishStatus = ({ currentBricks }: Props) => {
    return (
        <div className="bricks-publish-tab__status__start">
            <div className="bricks-publish-tab__status__start__container">
                <StatusIcon status={'readyToPublish'} />
                <div className="bricks-publish-tab__status__start__container__title-container">
                    <span className="bricks-publish-tab__status__start__container__title-container__title">
                        {OutputTabHelpers.generatePublishTitle(currentBricks)}
                    </span>
                    <span className="bricks-publish-tab__status__start__container__title-container__description">
                        {Translation.get('publishTab.start-status.description', 'bricks')}
                    </span>
                </div>
            </div>
            <div className="bricks-publish-tab__status__start__action">
                <Button variant="contained" disabled>
                    {Translation.get('output.publish', 'bricks')}
                </Button>
            </div>
        </div>
    );
};

export { StartPublishStatus };
