import React from 'react';
import '../styles/publish-summary-bar.scss';

interface Props {
    errors: number;
    warnings: number;
    reports: number;
}

const PublishSummaryBar = ({ errors, warnings, reports }: Props) => {
    const reportsWidth = (reports * 100) / (errors + warnings + reports);
    const warningsWidth = (warnings * 100) / (errors + warnings + reports);
    const errorsWidth = (errors * 100) / (errors + warnings + reports);
    return (
        <div className="publish-summary-bar">
            <div className="publish-summary-bar__success" style={{ width: `${reportsWidth}%` }}></div>
            <div className="publish-summary-bar__warning" style={{ width: `${warningsWidth}%` }}></div>
            <div className="publish-summary-bar__error" style={{ width: `${errorsWidth}%` }}></div>
        </div>
    );
};

export { PublishSummaryBar };
