import React from 'react';
import './../styles/publish-section.scss';

interface Props {
    title: string;
    children: React.ReactNode;
    subSection?: boolean;
    severity?: 'success' | 'warning' | 'error';
}

const PublishSection = ({ title, children }: Props) => {
    return (
        <div className="publish-section">
            <div className="publish-section__title">{title}</div>
            <div className="publish-section__details-container">{children}</div>
        </div>
    );
};

export { PublishSection };
