import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import '../styles/brick-select.scss';

interface Props {
    onSelectBrick: (brickId: string) => void;
    selectedBrick?: string;
    bricks: Brick[];
    showAllButton?: boolean;
    selectFirstBrick?: boolean;
}

interface SelectItem {
    key: string;
    label: string;
}

// The PublishTabFilterBrickSelect component is responsible for rendering a select input
// that allows the user to filter bricks by their id.
const PublishTabFilterBrickSelect = ({ selectedBrick, onSelectBrick, bricks, showAllButton = true }: Props) => {
    // Handle the change event of the select input
    const onChange = (event: SelectChangeEvent<unknown>) => {
        onSelectBrick(event.target.value as string);
    };

    // Generate the select options using useMemo for performance optimization
    const items = useMemo(() => {
        // Add an option to show all bricks
        const items: SelectItem[] = [];

        if (showAllButton)
            items.push({
                key: 'all',
                label: Translation.get('publishTab.filters.brickShowAll', 'bricks')
            });

        // Add an option for each brick
        bricks.forEach((brick) => {
            items.push({
                key: brick.id,
                label: brick.title
            });
        });

        return items;
    }, [bricks]);

    // If no brick is selected, default to 'all'
    const brick = selectedBrick || 'all';

    return (
        <Select className="publish-tab-filter-brick-select" value={brick} onChange={onChange}>
            {items.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export { PublishTabFilterBrickSelect };
